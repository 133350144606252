<template>
  <v-layout justify-center fill-height class="form">
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>{{$t("views.login.title")}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" @keyup.enter.native="loginUser">
            <v-text-field
              :prepend-icon="mdiAccountPath"
              v-model="details.UserName"
              v-bind:placeholder="$t('views.login.user')"
              type="text"
              required
              :rules="userValidation"
            ></v-text-field>
            <v-text-field
              :prepend-icon="mdiLockPath"
              v-model="details.Password"
              v-bind:placeholder="$t('views.login.password')"
              type="password"
              required
              :rules="passwordValidation"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click.native="loginUser">{{$t("common.buttons.login")}}</v-btn>
        </v-card-actions>
      </v-card>
      <div class="locale-changer">
        <!--<select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
        </select>-->
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import { mdiLock, mdiAccount } from '@mdi/js';
import { required, number, minLength } from "@/validation";

export default {
  data: () => ({
    details: {
      UserName: "",
      Password: ""
    },
    userValidation: [required, number],
    passwordValidation: [required, minLength(6)],
    langs: ["es", "pt"],
    mdiLockPath: mdiLock,
    mdiAccountPath: mdiAccount
  }),
  methods: {
    ...mapActions("notifications", ["error"]),
    ...mapActions(["login"]),

    loginUser() {
      const isValid = this.$refs.form.validate(true);

      if (!isValid) return;

      return this.login(this.details)
        .then(() => {
          this.$emit("login");
          this.$router.push("/");
        })
        .catch(error => {
          this.error(error.message);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
$break-small: 320px;
$break-medium: 650px;
$break-large: 1500px;

.form {
  @media screen and (min-width: $break-small) {
    margin-top: 10em;
  }
  @media screen and (min-width: $break-medium) {
    margin-top: 12em;
  }
  @media screen and (min-width: $break-large) {
    margin-top: 20em;
  }
}
</style>

